import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonName } from '@app/components/content/content.component';
import { AppointmentRequest } from '@app/models/appointmentRequest.model';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { FeatureFlagService, FeatureKey } from '@app/services/feature-flag.service';
import { APPOINTMENT_REQUEST, CUSTOMER_ID, LocalStorageService, SITE_BOOKING_SETTINGS } from '@app/services/local-storage.service';
import { NavigationService } from '@app/services/navigation.service';
import { SiteService } from '@app/services/site.service';
import { Service } from '@models/service.model';
import { ServiceService } from '@services/service.service';
import { Subject, Subscription, from, takeUntil } from 'rxjs';
import { ServiceCategoryType } from './service-category-type';

@Component({
    selector: 'app-add-service',
    templateUrl: './add-service.component.html',
    styleUrls: ['./add-service.component.scss'],
})
export class AddServiceComponent implements OnInit, OnDestroy {
    public formGroup: FormGroup;
    public ngUnsubscribe: Subject<any> = new Subject();
    private _destroyed$: Subject<void>;
    private navigationButtonSubscription!: Subscription;
    private isServicePriceAndDurationFeatureActive: boolean = false;
    serviceList: Service[] = [];
    categoryList: string[] = [];
    filteredServiceList: Service[] = [];
    selectedCategory: string;
    shouldShowServicePrice = false;
    isBookMultiServiceEnabled = false;
    public selectedServices: Service[] = [];
    appointmentRequest: AppointmentRequest;
    public get serviceFormControl() {
        return (this.formGroup?.controls['service'] as FormControl) || null;
    }
    constructor(
        private serviceService: ServiceService,
        private siteService: SiteService,
        private fb: FormBuilder,
        private localStorageService: LocalStorageService,
        private navigationService: NavigationService,
        private featureFlagService: FeatureFlagService,
        private cdRef: ChangeDetectorRef,
        @Inject(LOCALE_ID) public localeId: string
    ) {
        this._destroyed$ = new Subject();
        this.appointmentRequest = this.localStorageService.get(APPOINTMENT_REQUEST) as AppointmentRequest;
        this.selectedCategory = ServiceCategoryType.ALL;
        this.isServicePriceAndDurationFeatureActive = this.featureFlagService.getFeatureFlag(FeatureKey.ShowPriceAndDuration);
        this.isBookMultiServiceEnabled = this.featureFlagService.getFeatureFlag(FeatureKey.BookMultiService);
        this.formGroup = this.fb.group({
            service: new FormControl('', Validators.required),
        });
    }

    ngOnInit() {
        this.getSiteBookingSettings();
        this.fetchServices();
        /* istanbul ignore next */
        this.navigationButtonSubscription = this.navigationService.getNavigationButtonName().subscribe((buttonName) => {
            if (buttonName === ButtonName.SelectEmployee) {
                if (!this.isBookMultiServiceEnabled) {
                    this.selectedServices = this.serviceList.filter((s) => s.id === this.formGroup.value.service);
                } else {
                    this.selectedServices = this.serviceList.filter((s) => this.selectedServices.map((srv) => srv.id).includes(s.id));
                    this.setSelectedServicesFormGroupValue();
                }
                this.localStorageService.setNested(APPOINTMENT_REQUEST, 'service', this.selectedServices);
            }
        });
        this.formGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
            if (status === 'VALID') {
                this.navigationService.setIsStepFormValid(true);
            } else if (status === 'INVALID') {
                this.navigationService.setIsStepFormValid(false);
            }
        });

        this.previouslySelectedService();
    }

    toggleService(service: any) {
        if (service.checked) {
            this.selectedServices.push(this.serviceList.filter((s) => s.id === service.source.value)[0]);
        } else {
            this.selectedServices = this.selectedServices.filter((s) => s.id !== service.source.value);
        }
        this.setSelectedServicesFormGroupValue();
    }

    private setSelectedServicesFormGroupValue() {
        const selectedServicesString = this.selectedServices.map((service) => service.id).join(',');
        this.formGroup.get('service')?.patchValue(selectedServicesString);
    }

    checkSelectedServices(serviceId: string) {
        const selectedServicesIds = this.selectedServices.map((service) => service.id);
        return selectedServicesIds.includes(serviceId);
    }

    private getSiteBookingSettings() {
        const siteBookingSettings = this.localStorageService.get(SITE_BOOKING_SETTINGS) as SiteBookingSettings;
        if (siteBookingSettings) {
            this.shouldShowServicePrice = this.isServicePriceAndDurationFeatureActive && siteBookingSettings.showPricesWithServices;
        } else {
            const customerID = this.localStorageService.getString(CUSTOMER_ID);
            this.siteService
                .getSiteBookingSettings(customerID)
                .pipe(takeUntil(this._destroyed$))
                .subscribe((result) => {
                    this.localStorageService.set(SITE_BOOKING_SETTINGS, result);
                    this.shouldShowServicePrice = this.isServicePriceAndDurationFeatureActive && result.showPricesWithServices;
                    this.navigationService.setIsLoading(false);
                });
        }
    }

    private fetchServices() {
        from(this.serviceService.getServiceList())
            .pipe(takeUntil(this._destroyed$))
            .subscribe((serviceList) => {
                this.serviceList = serviceList.filter(
                    (service) => !service.categoryList.includes(ServiceCategoryType.DAYCARE) && service.employeeIDList?.length
                );
                this.serviceList.sort((a, b) => a.name.localeCompare(b.name));
                this.filteredServiceList = this.serviceList;
                void this.getCategories();
                this.formGroup.updateValueAndValidity();
            });
    }

    private async getCategories() {
        let categories: string[] = [];
        this.serviceList.forEach(function (service) {
            categories.push(...service.categoryList);
        });
        categories = categories.filter((c) => c !== '');
        this.categoryList = [...new Set(this.categoryList.concat(categories))].sort();
        this.categoryList.unshift(ServiceCategoryType.ALL);
        this.shoudHideRightArrow();
    }

    /* istanbul ignore next */
    private shoudHideRightArrow() {
        this.cdRef.detectChanges();
        const arrowIcons = document.querySelectorAll('.icon .mat-icon');
        const toggleGroup = document.querySelector('.horizontal-scroll-box') as HTMLElement;

        if (!arrowIcons[1].parentElement) {
            return;
        }

        const maxScrollableWidth = toggleGroup.scrollWidth - toggleGroup.clientWidth;
        const isAtEnd = maxScrollableWidth <= 0;
        arrowIcons[1].parentElement.style.display = isAtEnd ? 'none' : 'flex';
    }

    public onFilterValueChange($event: any) {
        if ($event.value !== ServiceCategoryType.ALL) {
            this.filteredServiceList = this.serviceList.filter((s) => s.categoryList.includes($event.value));
        } else {
            this.filteredServiceList = this.serviceList;
        }
        this.filteredServiceList.sort((a, b) => a.name.localeCompare(b.name));
        this.selectedCategory = $event.value;
    }

    isRadioButtonCheckedAndDepositRequired(serviceId: string): boolean {
        const service = this.serviceList.filter((s) => s.id === serviceId);
        const depositRequired = service[0].depositRequired ?? false;
        const depositPercentage = service[0].depositPercentage ?? 0;
        const depositFixedAmount = service[0].depositFixedAmount ?? 0;
        const selectedServiceId = this.formGroup.get('service')?.value;
        const selectedService = this.selectedServices.find((service) => service.id === serviceId);
        return (
            depositRequired && (depositPercentage > 0 || depositFixedAmount > 0) && (selectedServiceId === serviceId || !!selectedService)
        );
    }

    limitMaxLength(val: string) {
        return val?.length > 40 ? val.substring(0, 40) + '...' : val;
    }

    public showSelectedCategoryServicesCount(category: string) {
        return this.isBookMultiServiceEnabled && this.countSelectedServices(category) > 0;
    }

    public countSelectedServices(category: string) {
        return this.selectedServices.filter((service) => service.categoryList.includes(category)).length;
    }

    private previouslySelectedService() {
        if (this.appointmentRequest?.service?.length > 0) {
            if (!this.isBookMultiServiceEnabled) {
                this.formGroup.patchValue({ service: this.appointmentRequest.service[0].id });
                this.selectCategoryAndScrollToSelectedService(this.appointmentRequest.service[0]);
            } else {
                this.selectedServices = this.appointmentRequest.service;
                const selectedServicesIdsString = this.appointmentRequest.service.map((service) => service.id).join(',');
                this.formGroup.patchValue({ service: selectedServicesIdsString });
            }

            this.navigationService.setIsStepFormValid(true);
        } else {
            this.navigationService.setIsStepFormValid(false);
        }
    }

    private selectCategoryAndScrollToSelectedService(selectedService: Service) {
        setTimeout(() => {
            const categoryToSelect = this.categoryList.filter((category) => selectedService.categoryList.includes(category))[0];
            this.selectedCategory = categoryToSelect ?? ServiceCategoryType.ALL;
            this.onFilterValueChange({ value: this.selectedCategory });
            const id = `service-${selectedService.id}`;
            const element = document.getElementById(id) as HTMLElement;
            /* istanbul ignore next */
            element?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
    }

    ngOnDestroy() {
        this.navigationButtonSubscription.unsubscribe();
        this.ngUnsubscribe.complete();
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
