import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';
import { Client } from './client.model';
import { Employee } from './employee.model';
import { Pet } from './pet.model';
import { Service } from './service.model';
import { SyncAppointmentRequestDataResponse } from './syncAppointmentRequest.model';

export class AppointmentRequest {
    public service!: Service[];
    public petInfo!: Pet;
    public client!: Client;
    public employee!: Employee[];
    public datetime!: Date;
    public ticketID?: number;

    constructor(appointmentRequest: AppointmentRequest) {
        this.service = appointmentRequest.service;
        this.petInfo = appointmentRequest.petInfo;
        this.client = appointmentRequest.client;
        this.employee = appointmentRequest.employee;
        this.datetime = appointmentRequest.datetime;
        this.ticketID = appointmentRequest.ticketID;
    }

    public static deserializeAppSyncResponse(appSyncResponse: any): SyncAppointmentRequestDataResponse {
        return AppSyncResponse.parseResponse(appSyncResponse.data, (obj: SyncAppointmentRequestDataResponse) => obj, [
            'syncAppointmentRequest',
            'newValue',
        ]);
    }

    public static deserializeGetAppointmentRequestResponse(appSyncResponse: any): number | undefined {
        return AppSyncResponse.parseResponse(appSyncResponse.data, (obj: number) => obj, [
            'getAppointmentRequest',
            'appointmentRequest',
            'ticketID',
        ]);
    }
}
