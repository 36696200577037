import { AppointmentRequestService } from '@app/services/appointment-request.service';
import { dateToStringNoTimeZone } from '@app/shared/helpers/extensions';
import * as moment from 'moment';
import { AppointmentRequest } from './appointmentRequest.model';
import { Client } from './client.model';
import { CreditCard } from './creditCard.model';
import { Pet } from './pet.model';

export class SyncAppointmentRequestDataRequest {
    appointmentRequest: AppointmentRequestModel;
    localDateTime: string;
    token: string;

    constructor(appointmentRequestModel: AppointmentRequestModel, localDateTime: string, token: string) {
        this.appointmentRequest = appointmentRequestModel;
        this.localDateTime = localDateTime;
        this.token = token;
    }

    public static convertLocalStorageApptRequestToSyncApptRequestDataRequest(
        localStorageApptRequest: AppointmentRequest,
        daySmartAccountID: string,
        appointmentRequestService: AppointmentRequestService,
        token: string,
        creditCard?: CreditCard
    ): SyncAppointmentRequestDataRequest {
        const lastModifiedDateTime = new Date();
        const tempStartDateTime = new Date(localStorageApptRequest.datetime);
        const startDateTime = dateToStringNoTimeZone(tempStartDateTime);
        const duration = appointmentRequestService.getAppointmentRequestDuration(localStorageApptRequest);
        const endDateTime = dateToStringNoTimeZone(new Date(tempStartDateTime.getTime() + duration * 60000));
        const appointmentRequestItemList: AppointmentRequestItem[] = [];
        let itemStartDateTime = new Date(startDateTime);
        let itemEndDateTime = new Date(itemStartDateTime);
        for (const service of localStorageApptRequest.service) {
            const itemDuration = appointmentRequestService.getItemDuration(service, localStorageApptRequest.employee[0]);
            const resourceID = service.resourceIDList?.length ? service.resourceIDList[0] : undefined;
            itemEndDateTime = new Date(itemStartDateTime.getTime() + itemDuration * 60000);
            const appointmentRequestItem = new AppointmentRequestItem(
                service.id,
                localStorageApptRequest.employee[0].id,
                dateToStringNoTimeZone(itemStartDateTime),
                dateToStringNoTimeZone(itemEndDateTime),
                resourceID
            );
            appointmentRequestItemList.push(appointmentRequestItem);
            itemStartDateTime = new Date(itemEndDateTime);
        }

        let client;
        if (localStorageApptRequest.petInfo !== undefined) {
            const pet = new PetModel(localStorageApptRequest.petInfo, lastModifiedDateTime, daySmartAccountID);
            client = new ClientModel(localStorageApptRequest.client, lastModifiedDateTime, daySmartAccountID, pet);
        } else {
            client = new ClientModel(localStorageApptRequest.client, lastModifiedDateTime, daySmartAccountID);
        }
        client.creditCard = creditCard;
        const appointmentRequestModel = new AppointmentRequestModel(
            daySmartAccountID,
            client,
            startDateTime,
            endDateTime,
            AppointmentRequestStatuses.Pending,
            lastModifiedDateTime,
            appointmentRequestItemList,
            AppointmentRequestSources.Internal
        );
        const localDateTime = dateToStringNoTimeZone(moment().toDate());
        const syncAppointmentRequestDataRequest = new SyncAppointmentRequestDataRequest(appointmentRequestModel, localDateTime, token);
        return syncAppointmentRequestDataRequest;
    }
}
export class AppointmentRequestModel {
    public id?: string;
    public daySmartAccountID: string;
    public client?: ClientModel;
    public startDateTime: string;
    public endDateTime: string;
    public status: AppointmentRequestStatuses;
    public lastModifiedDateTime: Date;
    public itemList: AppointmentRequestItem[];
    public appointmentSource?: AppointmentRequestSources;
    public ticketID?: number;

    constructor(
        daySmartAccountID: string,
        client: ClientModel,
        startDateTime: string,
        endDateTime: string,
        status: AppointmentRequestStatuses,
        lastModifiedDateTime: Date,
        itemList: AppointmentRequestItem[],
        appointmentSource: AppointmentRequestSources,
        id?: string
    ) {
        this.id = id;
        this.daySmartAccountID = daySmartAccountID;
        this.client = client;
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
        this.status = status;
        this.lastModifiedDateTime = lastModifiedDateTime;
        this.itemList = itemList;
        this.appointmentSource = appointmentSource;
    }
}
class ClientModel {
    public id?: string;
    public firstName: string;
    public lastName: string;
    public cell: string;
    public email: string;
    public onlineID?: string;
    public lastModifiedDateTime: Date;
    public daySmartAccountID?: string;
    public pet?: PetModel;
    public enableEmailMarketing: boolean;
    public enableTextMarketing: boolean;
    public creditCard?: CreditCard;

    constructor(client: Client, lastModifiedDateTime: Date, daySmartAccountID: string, pet?: PetModel, creditCard?: CreditCard) {
        this.firstName = client.firstName;
        this.lastName = client.lastName;
        this.cell = client.phoneNumber;
        this.email = client.email;
        this.lastModifiedDateTime = lastModifiedDateTime;
        this.daySmartAccountID = daySmartAccountID;
        this.pet = pet;
        this.enableEmailMarketing = client.enableEmailMarketing;
        this.enableTextMarketing = client.enableTextMarketing;
        this.creditCard = creditCard;
    }
}
export class PetModel {
    public id?: string;
    public name: string;
    public type?: PetType;
    public breed?: PetBreed;
    public weight?: string;
    public clientID?: string;
    public daySmartAccountID?: string;
    public lastModifiedDateTime?: Date;

    constructor(pet: Pet, lastModifiedDateTime: Date, daySmartAccountID: string) {
        this.name = pet.name;
        if (pet.petType) {
            this.type = new PetType(pet.petType?.id, pet.petType?.name);
        }
        if (pet.petBreed) {
            this.breed = new PetBreed(pet.petBreed?.id, pet.petBreed?.name, pet.petBreed?.typeID);
        }
        if (pet.weight) {
            this.weight = pet.weight;
        }
        this.lastModifiedDateTime = lastModifiedDateTime;
        this.daySmartAccountID = daySmartAccountID;
    }
}
export class PetType {
    public id: string;
    public name: string;
    public daySmartAccountID?: string;
    public lastModifiedDateTime?: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

export class PetBreed {
    public id: string;
    public name: string;
    public typeID: string;
    public daySmartAccountID?: string;
    public lastModifiedDateTime?: string;

    constructor(id: string, name: string, typeID: string) {
        this.id = id;
        this.name = name;
        this.typeID = typeID;
    }
}
export class AppointmentRequestItem {
    public id?: string;
    public appointmentRequestID?: string;
    public serviceID: string;
    public employeeID: string;
    public resourceID?: string;
    public startDateTime: string;
    public endDateTime: string;

    constructor(
        serviceID: string,
        employeeID: string,
        startDateTime: string,
        endDateTime: string,
        resourceID?: string,
        id?: string,
        appointmentRequestID?: string
    ) {
        this.id = id;
        this.appointmentRequestID = appointmentRequestID;
        this.serviceID = serviceID;
        this.employeeID = employeeID;
        this.resourceID = resourceID;
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
    }
}
export enum AppointmentRequestSources {
    GoodFriend = 'goodfriend',
    Internal = 'internal',
}

export enum AppointmentRequestStatuses {
    Accepted = 'accepted',
    Pending = 'pending',
    Rejected = 'rejected',
    Closed = 'closed',
    Canceled = 'canceled',
    Voided = 'voided',
    NoShow = 'no-show',
    CheckedIn = 'checked-in',
}

export class SyncAppointmentRequestDataResponse {
    newValue?: AppointmentRequestModel;
    skipped?: boolean;
    oldValue?: AppointmentRequestModel;
}
