import { Injectable } from '@angular/core';
import { Site } from '@app/models/site.model';
import { SiteBookingSettings } from '@app/models/siteBookingSettings.model';
import { AppSyncResponse } from '@app/shared/helpers/appSyncResponse.helper';
import { environment } from '@environments/environment';
import { ApolloQueryResult } from 'apollo-client';
import gql from 'graphql-tag';
import { Observable, from, map } from 'rxjs';
import { AppSyncService } from '../app-sync.service';
import { NavigationService } from '../navigation.service';

@Injectable({
    providedIn: 'root',
})
export class SiteApiService {
    constructor(
        private appSyncService: AppSyncService,
        private navigationService: NavigationService
    ) {}

    private createGetSiteBookingSettingsQuery() {
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const getSiteBookingSettingsQuery = gql`
            query getSiteBookingSettings($daySmartAccountID: ID!) {
                getSiteBookingSettings(input: { daySmartAccountID: $daySmartAccountID }) {
                    siteBookingSettings {
                        businessAddress
                        businessName
                        businessPhoneNumber
                        themeColor
                        showPricesWithServices
                        showPricesWithEmployees
                        appointmentMaxAdvanceBooking
                        requireCCWhenBooking
                        apptCancellationPolicy
                        creditCardProcessingEnabled
                        creditCardProcessingMethod
                        stripeAccountID
                        customerCultureCode
                        allowClientOnlineAccountAccess
                    }
                }
            }
        `;
        return { client, getSiteBookingSettingsQuery };
    }

    /* istanbul ignore next */
    public async getSiteBookingSettingsAsync(daySmartAccountID: string): Promise<SiteBookingSettings> {
        const { client, getSiteBookingSettingsQuery } = this.createGetSiteBookingSettingsQuery();
        this.navigationService.setIsLoading(true);

        const queryResult = await client.query({
            query: getSiteBookingSettingsQuery,
            variables: { daySmartAccountID },
            fetchPolicy: 'no-cache',
        });
        return SiteBookingSettings.deserializeAppSyncResponse(queryResult as ApolloQueryResult<AppSyncResponse>);
    }

    public getSiteBookingSettings(daySmartAccountID: string): Observable<SiteBookingSettings> {
        const { client, getSiteBookingSettingsQuery } = this.createGetSiteBookingSettingsQuery();
        this.navigationService.setIsLoading(true);

        return from(
            client.query({
                query: getSiteBookingSettingsQuery,
                variables: { daySmartAccountID },
                fetchPolicy: 'no-cache',
            })
        ).pipe(
            map((queryResult) => {
                return SiteBookingSettings.deserializeAppSyncResponse(queryResult as ApolloQueryResult<AppSyncResponse>);
            })
        );
    }

    public async getSite(daySmartAccountID: string): Promise<Site> {
        const client = this.appSyncService.getClient(environment.serviceUrl);

        const getSiteQuery = gql`
            query getSiteByDaySmartAccountID($daySmartAccountID: ID!) {
                getSiteByDaySmartAccountID(input: { daySmartAccountID: $daySmartAccountID }) {
                    bookingURL
                    supportsPets
                }
            }
        `;
        const queryResult: ApolloQueryResult<AppSyncResponse> = await client.query({
            query: getSiteQuery,
            variables: { daySmartAccountID },
            fetchPolicy: 'no-cache',
        });

        return queryResult.data['getSiteByDaySmartAccountID'] as Site;
    }
}
