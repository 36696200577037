import { Injectable, NgZone } from '@angular/core';
import { environment } from '@environments/environment';
import { LDClient, LDSingleKindContext, initialize } from 'launchdarkly-js-client-sdk';
import { Observable, Subject } from 'rxjs';
import { CUSTOMER_ID, LocalStorageService } from './local-storage.service';

export enum FeatureKey {
    FirstAvailable = 'first-available',
    ShowPriceAndDuration = 'show-price-and-duration',
    UserAccounts = 'user-accounts',
    BookMultiService = 'book-multi-service',
    PosDsbGroupSync = 'pos-dsb-group-sync',
}

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService {
    private ldclient!: LDClient;

    private ready: Subject<boolean> = new Subject<boolean>();
    get ready$(): Observable<boolean> {
        return this.ready.asObservable();
    }

    constructor(
        private localStorageService: LocalStorageService,
        private _ngZone: NgZone
    ) {}

    public async initializeLaunchDarkly(supportsPets: boolean) {
        return this._ngZone.runOutsideAngular(() => {
            const user = this.setupLDUserObject(supportsPets);

            this.ldclient = initialize(environment.launchdarklyApiKey, user);

            return this.ldclient.waitUntilReady().then(() => {
                this.ready.next(true);
            });
        });
    }

    private setupLDUserObject(supportsPets: boolean): LDSingleKindContext {
        const cid = this.localStorageService.getString(CUSTOMER_ID);

        return {
            kind: 'user',
            key: cid,
            anonymous: false,
            supportsPets,
        };
    }

    public getFeatureFlag(featureKey: string): boolean {
        try {
            return this.ldclient.variation(featureKey, false);
        } catch {
            return false;
        }
    }
}
